@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJA.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fredoka One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fredokaone/v14/k3kUo8kEI-tA1RRcTZGmTmHB.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5VfkA.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNig.ttf) format('truetype');
}
@font-face {
  font-family: 'Passion One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/passionone/v18/PbynFmL8HhTPqbjUzux3JHuW.ttf) format('truetype');
}
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prompt/v10/-W__XJnvUD7dzB26Zw.ttf) format('truetype');
}
@font-face {
  font-family: 'Abel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/abel/v18/MwQ5bhbm2POE6Vg.ttf) format('truetype');
}
@font-face {
  font-family: 'Abril Fatface';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/abrilfatface/v23/zOL64pLDlL1D99S8g8PtiKchm-A.ttf) format('truetype');
}
@font-face {
  font-family: 'BioRhyme';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/biorhyme/v16/1cXwaULHBpDMsHYW_HxG.ttf) format('truetype');
}
@font-face {
  font-family: 'BioRhyme Expanded';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dQIE1zZzytGswgU577CDY9LjbffySU.ttf) format('truetype');
}
@font-face {
  font-family: 'Buenard';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/buenard/v17/OD5DuM6Cyma8FnnsPzc.ttf) format('truetype');
}
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fahkwang/v16/Noax6Uj3zpmBOgbNpNqP.ttf) format('truetype');
}
@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fjallaone/v15/Yq6R-LCAWCX3-6Ky7FAFnOY.ttf) format('truetype');
}
@font-face {
  font-family: 'Gotu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gotu/v14/o-0FIpksx3QOlH0.ttf) format('truetype');
}
@font-face {
  font-family: 'Gruppo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gruppo/v21/WwkfxPmzE06v_ZWFWQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXME.ttf) format('truetype');
}
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lobster/v30/neILzCirqoswsqX9_oU.ttf) format('truetype');
}
@font-face {
  font-family: 'Marcellus';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/marcellus/v13/wEO_EBrOk8hQLDvIAF8FUQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/merriweather/v30/u-440qyriQwlOrhSvowK_l5Oew.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/newscycle/v23/CSR64z1Qlv-GDxkbKVQ_TOc.ttf) format('truetype');
}
@font-face {
  font-family: 'Notable';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notable/v18/gNMEW3N_SIqx-WX9-HM.ttf) format('truetype');
}
@font-face {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oldstandardtt/v20/MwQubh3o1vLImiwAVvYawgcf2eVurQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUE.ttf) format('truetype');
}
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ96A.ttf) format('truetype');
}
@font-face {
  font-family: 'Patua One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/patuaone/v20/ZXuke1cDvLCKLDcimxBI5A.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQw.ttf) format('truetype');
}
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA-M-.ttf) format('truetype');
}
@font-face {
  font-family: 'Stint Ultra Expanded';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stintultraexpanded/v22/CSRg4yNNh-GbW3o3JkwoDcdvMKMf0oBAd0qo.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v20/LYjYdG7kmE0gV69VVPPdFl06VN8XG7Sy.ttf) format('truetype');
}
@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/varelaround/v20/w8gdH283Tvk__Lua32TysjIvoA.ttf) format('truetype');
}
@font-face {
  font-family: 'Volkhov';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/volkhov/v17/SlGQmQieoJcKemNeQTI.ttf) format('truetype');
}
@font-face {
  font-family: 'Martel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/martel/v10/PN_xRfK9oXHga0XtYQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v22/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf) format('truetype');
}
@font-face {
  font-family: 'Martel Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/martelsans/v12/h0GsssGi7VdzDgKjM-4d8ijf.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4G1ilntA.ttf) format('truetype');
}
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RUAw.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptserif/v18/EJRVQgYoZZY2vCFuvDFR.ttf) format('truetype');
}
@font-face {
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bitter/v32/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbfCL8.ttf) format('truetype');
}
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimo/v28/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxsBw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eA.ttf) format('truetype');
}
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjd1ak.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk.ttf) format('truetype');
}
@font-face {
  font-family: 'Ek Mukta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ekmukta/v23/mem9YaCmzCuv3KJUDLYV.ttf) format('truetype');
}
@font-face {
  font-family: 'Overpass Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/overpassmono/v15/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EXUokzz.ttf) format('truetype');
}
@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oxygen/v15/2sDfZG1Wl4Lcnbu6iQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frankruhllibre/v20/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw7FYVqQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz76CyzC1Esg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif TC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoseriftc/v23/XLYgIZb5bJNDGYxLBibeHZ0BtnAOSw.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaG9_FrYtHaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifsc/v22/H4chBXePl9DZ0Xe7gG9cyOj7kqGWbQ.otf) format('opentype');
}
@font-face {
  font-family: 'Liu Jian Mao Cao';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/liujianmaocao/v20/845DNN84HJrccNonurqXILGpvCOoTe3fLA.ttf) format('truetype');
}
@font-face {
  font-family: 'Long Cang';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/longcang/v17/LYjAdGP8kkgoTec8zkRQqnov.ttf) format('truetype');
}
@font-face {
  font-family: 'Ma Shan Zheng';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mashanzheng/v10/NaPecZTRCLxvwo41b4gvzkXadMPmCA.ttf) format('truetype');
}
@font-face {
  font-family: 'ZCOOL KuaiLe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zcoolkuaile/v19/tssqApdaRQokwFjFJjvM6h2moYb3.ttf) format('truetype');
}
@font-face {
  font-family: 'ZCOOL QingKe HuangYou';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zcoolqingkehuangyou/v15/2Eb5L_R5IXJEWhD3AOhSvFC554MOOahI4mR4jPe4.ttf) format('truetype');
}
@font-face {
  font-family: 'ZCOOL XiaoWei';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zcoolxiaowei/v14/i7dMIFFrTRywPpUVX9_RJyM1UFWaGw.ttf) format('truetype');
}
@font-face {
  font-family: 'Zhi Mang Xing';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zhimangxing/v17/f0Xw0ey79sErYFtWQ9a2rq-g4aAneQ.ttf) format('truetype');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  text-align: center;
}
/* scrollbar width */
::-webkit-scrollbar {
  width: 8px;
}
/* scrollbar Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}
/* scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 10px;
  background: #555;
}
.w-color-interactive {
  border-radius: 0 !important;
}
.image-dragger {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
@layer components {
  .upgrade-circle {
    @apply before:mr-2 before:block before:h-3 before:w-3 before:rounded-full before:border before:border-gray-900 before:content-[""];
  }
  .chevron-right {
    @apply after:absolute after:left-0 after:block after:h-3 after:w-3 after:content-[""];
  }
  .chevron-right::after {
    background-image: url("./media/svg/upgrade/chevron_right_dark.svg");
    background-repeat: no-repeat;
  }
  .builder-row {
    @apply relative mx-auto !w-[80%] shadow-xl;
    @apply before:absolute before:left-0 before:top-0 before:h-full before:w-[12.5%] before:-translate-x-full before:opacity-30 before:content-[''];
    @apply after:absolute after:right-0 after:top-0 after:h-full after:w-[12.5%] after:translate-x-full after:opacity-30 after:content-[''];
    @apply hover:before:bg-gray-400 hover:after:bg-gray-400;
  }
  .builder-row:hover {
    @apply border-b-2 border-white;
  }
  .builder-row:hover .toolbar {
    @apply !text-black;
  }
  .builder-row:hover .toolbar,
  .builder-row:hover .add-block {
    @apply !flex;
  }
  .builder-row .editor-title,
  .builder-row .editor-content {
    @apply hover:box-border hover:cursor-pointer hover:ring-4 hover:ring-indigo-400;
  }
  .builder-row .editor-link,
  .builder-row .editor-button {
    @apply relative;
    @apply before:absolute before:-left-2 before:-top-2 before:z-10 before:box-border before:hidden before:h-[calc(100%+1rem)] before:w-[calc(100%+1rem)] before:cursor-pointer before:bg-white before:bg-opacity-10 before:ring-4 before:ring-indigo-400 before:content-[''];
    @apply hover:before:block;
  }
  .builder-row .editor-image {
    @apply relative flex min-h-full;
    @apply before:absolute before:inset-2 before:left-4 before:top-4 before:z-10 before:box-border before:hidden before:h-[calc(100%-2rem)] before:w-[calc(100%-2rem)] before:cursor-pointer before:bg-white before:bg-opacity-10 before:ring-4 before:ring-indigo-400 before:content-[''];
    @apply hover:before:block;
  }
  .builder-row .splide__slide .editor-image {
    @apply before:z-0;
  }
  .builder-row [data-holder="editor-title"] .ce-toolbar {
    @apply !hidden;
  }
  .preview-builder {
    @apply fixed inset-0 z-50 flex flex-col;
  }
  .preview-builder > * {
    width: 100% !important;
  }
  .preview-builder .main-container {
    overflow: auto;
  }
  .preview-builder .main-container .content > .builder-row {
    width: 100% !important;
  }
  .preview-builder .main-container .editor-title:hover,
  .preview-builder .main-container .editor-content:hover {
    @apply !pointer-events-none !ring-0;
  }
  .preview-builder .main-container .editor-title:hover:before,
  .preview-builder .main-container .editor-content:hover:before {
    display: none !important;
  }
  .purpose-carousel {
    @apply relative w-72;
  }
  .purpose-carousel .purpose-container {
    width: calc(500% + 5 * 20px);
  }
  .purpose-carousel .purpose-container > div {
    @apply relative;
    @apply float-left block !w-72;
    @apply flex items-center justify-center shadow-md;
    @apply opacity-30;
  }
  .purpose-carousel .purpose-container > div.active {
    @apply opacity-100;
    @apply before:absolute before:-inset-2 before:border-2 before:border-indigo-500 before:content-[''];
    @apply -top-6;
  }
  .purpose-carousel > button {
    @apply z-10 bg-white bg-opacity-80 text-gray-500;
    @apply disabled:opacity-10;
  }
  .purpose-range {
    @apply relative;
  }
  .purpose-range input[type="range"] {
    -webkit-appearance: none;
  }
  .purpose-range input[type="range"]::-webkit-slider-thumb,
  .purpose-range input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
  }
  .purpose-range input[type="range"]::-webkit-slider-runnable-track {
    width: 200px;
    height: 10px;
    background: #aaa;
    @apply rounded-full;
  }
  .purpose-range input[type="range"]::-webkit-slider-thumb {
    @apply relative top-1/2 h-10 w-10 -translate-y-1/2 transform cursor-pointer rounded-full bg-white shadow;
    @apply bg-move-horizontal bg-contain bg-center bg-no-repeat;
    @apply z-20;
    border: 2px solid #000;
  }
  .selectpage-option {
    @apply relative cursor-pointer;
    @apply before:absolute before:-inset-1 before:hidden before:h-[calc(100%+2rem)] before:w-[calc(100%+2rem)] before:border-4 before:border-indigo-500 before:content-[''];
    @apply hover:before:block;
  }
  .selectpage-option.selected {
    @apply before:block;
  }
  .image-thumbnail {
    @apply relative cursor-pointer;
    @apply z-10 before:absolute before:-inset-1 before:hidden before:h-auto before:w-auto before:border-2 before:border-indigo-500 before:content-[''];
    @apply hover:before:block;
  }
  .image-thumbnail.selected {
    @apply before:block;
  }
  .preview-box {
    @apply relative w-full cursor-pointer overflow-hidden p-2;
    @apply before:absolute before:left-0 before:top-0 before:h-full before:w-full before:content-[''] before:hover:border before:hover:border-indigo-500;
    @apply after:absolute after:left-0 after:top-0 after:z-10 after:h-full after:w-full after:bg-black after:opacity-0 after:content-[''];
  }
  .preview-box .button {
    @apply absolute bottom-0 left-0 z-10 flex h-10 w-full translate-y-full content-center items-center justify-center bg-gray-600 text-white opacity-80;
  }
  .preview-box:hover .button {
    @apply translate-y-0 duration-100 ease-in;
  }
  .cookies-content h6 {
    @apply opacity-70;
  }
  .style-box {
    @apply relative cursor-pointer;
    @apply before:absolute before:-inset-2 before:h-[calc(100%+1rem)] before:w-[calc(100%+1rem)] before:border-2 before:border-indigo-500 before:opacity-0 before:content-[''];
    @apply duration-300 ease-in hover:before:opacity-100 before:hover:transition-opacity;
  }
  .style-box.enlarge {
    @apply hover:transform hover:scale-[calc(450/1024)];
    @apply before:border-8;
  }
  .style-box.color-picker {
    @apply before:rounded-full;
  }
  .style-box.selected {
    @apply before:opacity-100;
  }
  .btn.btn-primary {
    @apply bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600;
  }
  .alert.alert-success {
    @apply bg-green-50;
  }
  .alert.alert-success .title {
    @apply text-green-800;
  }
  .alert.alert-success .content {
    @apply text-green-700;
  }
  .alert.alert-success .icon {
    @apply text-green-400;
  }
  .alert.alert-success .button {
    @apply bg-green-50 text-green-800 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50;
  }
  .alert.alert-info {
    @apply bg-blue-50;
  }
  .alert.alert-info .title {
    @apply text-blue-800;
  }
  .alert.alert-info .content {
    @apply text-blue-700;
  }
  .alert.alert-info .icon {
    @apply text-blue-400;
  }
  .alert.alert-info .button {
    @apply bg-blue-50 text-blue-800 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50;
  }
  .alert.alert-warning {
    @apply bg-yellow-50;
  }
  .alert.alert-warning .title {
    @apply text-yellow-800;
  }
  .alert.alert-warning .content {
    @apply text-yellow-700;
  }
  .alert.alert-warning .icon {
    @apply text-yellow-400;
  }
  .alert.alert-warning .button {
    @apply bg-yellow-50 text-yellow-800 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50;
  }
  .alert.alert-error {
    @apply bg-red-50;
  }
  .alert.alert-error .title {
    @apply text-red-800;
  }
  .alert.alert-error .content {
    @apply text-red-700;
  }
  .alert.alert-error .icon {
    @apply text-red-400;
  }
  .alert.alert-error .button {
    @apply bg-red-50 text-red-800 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50;
  }
  .wizard.persons {
    @apply relative;
  }
  .wizard.persons.box {
    @apply relative mx-2 mb-5 flex h-full w-full cursor-pointer select-none flex-row items-center gap-2 border-2 border-solid bg-clip-padding p-3 py-3 leading-6 transition duration-300 ease-out hover:cursor-pointer hover:border-transparent hover:shadow-md sm:w-full sm:flex-col sm:items-start sm:p-6;
  }
  .wizard.persons.box-hover {
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:transition before:duration-500 before:ease-in-out before:content-[''] before:hover:border-2 before:hover:border-indigo-600 before:hover:ease-in;
  }
  .wizard.persons.box-selected {
    @apply relative;
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:border-2 before:border-indigo-600 before:transition before:duration-500 before:ease-out before:content-[''];
  }
  .wizard.persons.icon {
    @apply h-6 w-6 cursor-pointer align-middle leading-6 transition;
  }
  .wizard.persons.checkIcon {
    @apply absolute right-4 top-4 h-6 w-6 cursor-pointer align-middle leading-6 text-indigo-600 transition;
  }
  .wizard.persons.image {
    @apply hidden h-16 w-auto cursor-pointer align-middle leading-6 transition sm:block;
  }
  .wizard.subpages {
    @apply relative;
  }
  .wizard.subpages.box {
    @apply relative mx-2 mb-5 flex h-20 w-auto cursor-pointer select-none items-center border-2 border-solid bg-clip-padding p-3 leading-6 transition duration-300 ease-out hover:cursor-pointer hover:border-transparent hover:shadow-md;
  }
  .wizard.subpages.box-hover {
    @apply before:absolute before:-inset-2.5 before:h-24 before:w-auto before:transition before:duration-500 before:ease-in-out before:content-[''] before:hover:border-2 before:hover:border-indigo-600 before:hover:ease-in;
  }
  .wizard.subpages.box-selected {
    @apply relative;
    @apply before:absolute before:-inset-2.5 before:h-24 before:w-auto before:border-2 before:border-indigo-600 before:transition before:duration-500 before:ease-out before:content-[''];
  }
  .wizard.subpages.icon {
    @apply absolute right-4 top-4 h-6 w-6 cursor-pointer align-middle leading-6 text-white transition;
  }
  .wizard.goal {
    @apply relative;
  }
  .wizard.goal.box {
    @apply relative mx-2 mb-5 flex h-full max-h-48 w-auto cursor-pointer select-none flex-row items-center gap-2 border-2 border-solid bg-clip-padding py-3 leading-6 transition duration-300 ease-out hover:cursor-pointer hover:border-transparent hover:shadow-md sm:w-full sm:flex-col sm:items-start sm:p-3;
  }
  .wizard.goal.box-hover {
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:transition before:duration-500 before:ease-in-out before:content-[''] before:hover:border-2 before:hover:border-indigo-600 before:hover:ease-in;
  }
  .wizard.goal.box-selected {
    @apply relative;
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:border-2 before:border-indigo-600 before:transition before:duration-500 before:ease-out before:content-[''];
  }
  .wizard.goal.icon {
    @apply h-6 w-6 cursor-pointer align-middle leading-6 transition;
  }
  .wizard.goal.checkIcon {
    @apply absolute right-4 top-4 h-6 w-6 cursor-pointer align-middle leading-6 text-white transition;
  }
  .wizard.goal.image {
    @apply hidden h-16 w-auto cursor-pointer align-middle leading-6 transition sm:block;
  }
  .wizard.stylePage {
    @apply relative;
  }
  .wizard.stylePage.box {
    @apply relative mb-5 flex h-24 w-auto cursor-pointer select-none flex-row items-center border-2 border-solid bg-clip-padding leading-6 transition duration-300 ease-out hover:cursor-pointer hover:border-transparent hover:shadow-md sm:w-full sm:flex-col sm:items-start;
  }
  .wizard.stylePage.box-hover {
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:transition before:duration-500 before:ease-in-out before:content-[''] before:hover:border-2 before:hover:border-indigo-600 before:hover:ease-in;
  }
  .wizard.stylePage.box-selected {
    @apply relative;
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:border-2 before:border-indigo-600 before:transition before:duration-500 before:ease-out before:content-[''];
  }
  .wizard.stylePage.icon {
    @apply h-6 w-6 cursor-pointer align-middle leading-6 transition;
  }
  .wizard.stylePage.image {
    @apply hidden h-16 w-auto cursor-pointer align-middle leading-6 transition sm:block;
  }
  .wizard.stylePage.checkIcon {
    @apply absolute right-4 top-2 h-6 w-6 cursor-pointer align-middle leading-6 text-white transition;
  }
  .wizard.topic {
    @apply relative;
  }
  .wizard.topic.box {
    @apply relative mb-5 flex h-32 w-auto cursor-pointer select-none flex-row items-center border-2 border-solid bg-clip-padding leading-6 transition duration-300 ease-out hover:cursor-pointer hover:border-transparent hover:shadow-md sm:h-48 sm:w-full sm:flex-col sm:items-start;
  }
  .wizard.topic.box-hover {
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:transition before:duration-500 before:ease-in-out before:content-[''] before:hover:border-2 before:hover:border-indigo-600 before:hover:ease-in;
  }
  .wizard.topic.box-selected {
    @apply relative;
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:border-2 before:border-indigo-600 before:transition before:duration-500 before:ease-out before:content-[''];
  }
  .wizard.topic.icon {
    @apply h-6 w-6 cursor-pointer align-middle leading-6 transition;
  }
  .wizard.topic.image {
    @apply hidden h-16 w-auto cursor-pointer align-middle leading-6 transition sm:block;
  }
  .wizard.topic.checkIcon {
    @apply absolute right-4 top-2 h-6 w-6 cursor-pointer align-middle leading-6 text-white transition;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
  }
  .wizard.topic.text-stroke {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
  }
  .wizard.color {
    @apply relative;
  }
  .wizard.color.box {
    @apply relative mx-2 mb-5 flex h-20 w-auto cursor-pointer select-none flex-row items-center gap-2 border-2 border-solid bg-clip-padding p-3 py-2 leading-6 transition duration-300 ease-out hover:cursor-pointer hover:border-transparent hover:shadow-md sm:w-full sm:flex-col sm:items-start;
  }
  .wizard.color.box-hover {
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:transition before:duration-500 before:ease-in-out before:content-[''] before:hover:border-2 before:hover:border-indigo-600 before:hover:ease-in;
  }
  .wizard.color.box-selected {
    @apply relative;
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:border-2 before:border-indigo-600 before:transition before:duration-500 before:ease-out before:content-[''];
  }
  .wizard.color.icon {
    @apply h-6 w-6 cursor-pointer align-middle leading-6 transition;
  }
  .wizard.color.image {
    @apply hidden h-16 w-auto cursor-pointer align-middle leading-6 transition sm:block;
  }
  .wizard.selectPage {
    @apply relative;
  }
  .wizard.selectPage.box {
    @apply relative h-full w-auto cursor-pointer select-none items-center border-2 border-solid bg-clip-padding leading-6 transition duration-300 ease-out hover:cursor-pointer hover:border-transparent hover:shadow-md sm:w-full sm:flex-col sm:items-start sm:p-3;
  }
  .wizard.selectPage.box-hover {
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:transition before:duration-500 before:ease-in-out before:content-[''] before:hover:border-2 before:hover:border-indigo-600 before:hover:ease-in;
  }
  .wizard.selectPage.box-selected {
    @apply relative;
    @apply before:absolute before:-inset-2.5 before:h-auto before:w-auto before:border-2 before:border-indigo-600 before:transition before:duration-500 before:ease-out before:content-[''];
  }
  .wizard.selectPage.icon {
    @apply h-6 w-6 cursor-pointer align-middle leading-6 transition;
  }
  .wizard.selectPage.image {
    @apply hidden h-16 w-auto cursor-pointer align-middle leading-6 transition sm:block;
  }
  .wizard.selectPage.checkIcon {
    @apply absolute right-4 top-2 z-20 h-6 w-6 cursor-pointer align-middle leading-6 text-indigo-600 transition;
  }
}
